import { CSSTransition } from "react-transition-group";
import { createPortal } from 'react-dom'
import './modal.less'
import "animate.css";

export const Modal = ({ open, image, buttonContent, onNext, onAnimationEnd }) => {
    // http://reactcommunity.org/react-transition-group/css-transition/
    // in属性为true/false，true为展开动效，false为关闭动效
    return createPortal(
      <CSSTransition 
        in={open} 
        timeout={500} 
        classNames="diy-transition"
        unmountOnExit 
        onExited={onAnimationEnd}
        appear>
        <div className="modal-container">
            <img className='modal-content' src={image} alt='结果图'/>
            <img className='modal-next' src={buttonContent} alt='下一关' onClick={onNext}/>
        </div>
      </CSSTransition>,
      document.body,
    );
  };