import {Button} from 'antd'
import React, {useEffect, useState} from 'react'
import classNames from 'classnames'
import './index.less'
import {Matrix} from './typings'
import {Modal} from './modal'

const imageSuffix = '.png'
const maxRound = 1
const matrixUpgradeRound = 1
const gameTypePrefix = ""

const initMatrixSize: Matrix.State['size'] = {
    width: 2,
    height: 2,
}

interface HasBegunTime {
    timer: null | NodeJS.Timeout
    seconds: number
}

function PushSquare(): JSX.Element {
    const [matrix, setMatrix] = useState<Matrix.State>({
        size: {...initMatrixSize},
        source: initMatrixSource(initMatrixSize, 1),
        isStart: false,
        round: 0,
        showModal: false,
    })
    const [hasBegunTime, setHasBegunTime] = useState<HasBegunTime>({
        timer: null,
        seconds: 0,
    })
    var gridSize = getMatrixGridSize(matrix)

    const [authKey, setAuthKey] = useState(localStorage.getItem('authKey') || null);


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('authkey')) {
            localStorage.setItem('authKey', urlParams.get('authkey'))
            setAuthKey(urlParams.get('authkey'))
            console.log('set authkey')
        }
    }, [window.location.search])


    return (
        <div className='bgContainer'>
            <Modal
                open={matrix.showModal}
                image={getResultImagePath(matrix.round)}
                buttonContent={getResultModalBtnContent(matrix.round)}
                onNext={() => {
                    onPromoteRequest()
                }}
                onAnimationEnd={() => {
                    promote()
                }}/>
            <audio id="background-music" src="/audio/bgAudio.mp3" preload='auto'></audio>
            <img className='background' src={getPrimaryBackgroundImagePath(matrix.round)} alt='背景图'
                 width={window.innerWidth} height={window.innerHeight + 12}/>
            <div className={classNames('push-square', {'push-square-condition': (matrix.round === 0)})}>
                <div className='timer'>
                    <img className='timer-bg' src="/pics/items/countdown_bg.png" alt='计时器背景'/>
                    <p className='timer-content'>{getTimeFromSeconds(hasBegunTime.seconds)}</p>
                </div>
                <div className='matrix' style={{width: matrix.size.width * (matrix.size.width >= 3 ? 100 : 150)}}>
                    {matrix.source.map((e, yIndex) =>
                        e.map((ee, xIndex) => (
                            <div
                                key={`${xIndex}${yIndex}`}
                                className='matrix-grid'
                                style={{height: gridSize, flexBasis: gridSize}}
                                onClick={matrixGridClick.bind(null, xIndex, yIndex)}>
                                <img src={ee.image} alt='宫格图' width={gridSize} height={gridSize}/>
                                <div
                                    className={classNames('matrix-grid-cover', {'matrix-grid-move-point': ee.isMovePoint && matrix.isStart})}/>
                            </div>
                        ))
                    )}
                </div>
                <div className='console'>
                    {!matrix.isStart &&
                        <img src="/pics/items/btn_start.png" alt='开始' width={136} onClick={startRound}/>}
                    <p className={classNames('operation-hint', {'operation-hint-condition': matrix.isStart})}>点击红色格子相邻的格子进行交换</p>
                </div>
            </div>

            {authKey ?
                <img className={classNames('btn-start-game', {'btn-start-game-condition': (matrix.round > 0)})}
                     src="/pics/items/btn_trigger.png" alt='立刻开始' width={136} onClick={startGame}/>
                :
                <img className={classNames('btn-start-game', {'btn-start-game-condition': (matrix.round > 0)})}
                     src="/pics/items/login.png" alt='登录' width={136} onClick={login}/>
            }


        </div>
    )

    function login(): void {
        console.log('login')
        window.location.href = 'http://www.dizalpharma.com:9080/oasys/api/web/h5login?url=https://puzzle.daodaolang.com'
        return
    }

    function getResultModalBtnContent(round): string {
        if (round === maxRound) {
            return "/pics/items/btn_complete.png"
        }
        return "/pics/items/btn_next.png"
    }

    function getPrimaryBackgroundImagePath(round): string {
        return "/pics" + gameTypePrefix + "/roundBg/" + round + imageSuffix
    }

    function getResultImagePath(round): string {
        return "/pics" + gameTypePrefix + "/roundResult/" + 1 + imageSuffix
    }

    function getMatrixGridImagePath(matrixSize, round, id): string {
        return "/pics" + gameTypePrefix + "/" + matrixSize + "/" + round + "/" + id + imageSuffix
    }

    function getMatrixGridSize(matrix): number {
        if (matrix.size.width < 3) {
            return 147;
        }
        return 97;
    }

    function getTimeFromSeconds(totalSeconds) {
        if (totalSeconds < 86400) {
            var dt = new Date("01/01/2000 0:00");
            dt.setSeconds(totalSeconds);
            return formatTime(dt);
        } else {
            return null;
        }
    }

    function formatTime(dt) {
        var h = dt.getHours(),
            m = dt.getMinutes(),
            s = dt.getSeconds(),
            r = "";
        if (h > 0) {
            r += (h > 9 ? h.toString() : "0" + h.toString()) + ":";
        }
        r += (m > 9 ? m.toString() : "0" + m.toString()) + ":"
        r += (s > 9 ? s.toString() : "0" + s.toString());
        return r;
    }

    function startGame(): void {
        if (matrix.round > 0) {
            return
        }
        setMatrix(state => ({
            ...state,
            round: 1,
        }))
        playBgMusic()
    }

    function startRound(): void {
        if (matrix.isStart) {
            return
        }

        setMatrix(state => ({
            ...state,
            isStart: true,
            source: upsetMatrixSource(state.source),
        }))

        startTimekeeping()
    }

    function onPromoteRequest(): void {
        setMatrix(state => ({
            ...state,
            isStart: false,
            showModal: false
        }))
        resetTimer()
    }

    function promote(): void {
        // if (matrix.round === matrixUpgradeRound) {
        //     matrixUpdate(3, matrix.round + 1)
        //     return
        // }
        if (matrix.round === maxRound) {
            matrixUpdate(2, 1)
            return
        }
        setMatrix(state => ({
            ...state,
            source: initMatrixSource(state.size, state.round + 1),
            round: state.round + 1
        }))

    }

    function startTimekeeping(): void {
        const hasBegunTimer = setInterval(() => {
            setHasBegunTime(state => ({
                ...state,
                seconds: state.seconds + 1,
            }))
        }, 1000)

        setHasBegunTime(state => ({
            ...state,
            seconds: 0,
            timer: hasBegunTimer,
        }))
    }

    function resetTimer(): void {
        clearInterval(hasBegunTime.timer!)
        setHasBegunTime(state => ({
            ...state,
            seconds: 0
        }))
    }

    function matrixUpdate(newSize: number, round: number): void {
        setMatrix(state => {
            state.size = {
                width: newSize,
                height: newSize,
            }
            state.source = initMatrixSource(state.size, round)
            state.round = round
            return {...state}
        })
    }

    function verifCorrect(matrixSource): boolean {
        let previousId = 0
        return matrixSource.every(e =>
            e.every(ee => {
                previousId++
                return ee.id === previousId
            })
        )
    }

    function playBgMusic() {
        let audioToPlay = document.getElementById('background-music');
        audioToPlay.play();
        audioToPlay.addEventListener('ended', function () {
            audioToPlay.currentTime = 0;
            audioToPlay.play();
        }, false);
    }

    function matrixGridClick(clickXIndex: number, clickYIndex: number): void {
        if (!matrix.isStart) {
            return
        }

        const {source: matrixSource} = matrix
        const movePointIndex = getMovePointIndex()

        if (isMatrixMove()) {
            exchangeMatrixGrid()
            if (verifCorrect(matrixSource)) {
                fetch('/api/v1/puzzle-game-report-result', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        game: `${matrixSource.length}x${matrixSource[0].length}`,
                        authKey: authKey,
                        seconds: hasBegunTime.seconds,
                    })
                })

                clearInterval(hasBegunTime.timer!)

                setMatrix(state => ({
                    ...state,
                    source: matrixSource,
                    showModal: true
                }))
            } else {
                setMatrix(state => ({
                    ...state,
                    source: matrixSource,
                }))
            }
        }

        function exchangeMatrixGrid(): void {
            const {x: movePointX, y: movePointY} = movePointIndex
            ;[matrixSource[movePointY][movePointX], matrixSource[clickYIndex][clickXIndex]] = [
                matrixSource[clickYIndex][clickXIndex],
                matrixSource[movePointY][movePointX],
            ]
        }

        function isMatrixMove(): boolean {
            const {x, y} = movePointIndex
            return (
                ([y + 1, y - 1].includes(clickYIndex) && x === clickXIndex) ||
                ([x + 1, x - 1].includes(clickXIndex) && y === clickYIndex)
            )
        }
    }

    function initMatrixSource(matrixSize: Matrix.State['size'], round: number): Matrix.State['source'] {
        const matrixSource: Matrix.State['source'] = []
        const {height: matrixH, width: matrixW} = matrixSize ?? matrix.size
        let id = 0
        for (let i = 0; i < matrixH; i++) {
            matrixSource[i] = []
            for (let ii = 0; ii < matrixW; ii++) {
                id++
                const matrixGrid: Matrix.Source = {
                    id,
                    image: getMatrixGridImagePath(matrixW * matrixH, round, id),
                    showContent: id,
                    isMovePoint: false
                }
                if (i === matrixH - 1 && ii === matrixW - 1) {
                    matrixGrid.isMovePoint = true
                }
                matrixSource[i].push(matrixGrid)
            }
        }
        return matrixSource
    }

    function upsetMatrixSource<T = Matrix.State['source']>(matrixSource: T): T {
        let {x: movePointX, y: movePointY} = getMovePointIndex()
        const {width: matrixW, height: matrixH} = matrix.size

        // eslint-disable-next-line
        let previous5Step: string[] = []
        for (let i = 0; i < 10000; i++) {
            // eslint-disable-next-line
            const mayMobileIndexs: string[] = getMayMobileIndexs().filter(
                // eslint-disable-next-line
                xyStr => !previous5Step.includes(xyStr)
            )
            while (true) {
                if (mayMobileIndexs.length === 0) {
                    previous5Step = []
                    break
                }
                const randomNum = ~~(Math.random() * mayMobileIndexs.length)
                let [x, y] = mayMobileIndexs[randomNum].split(',') as any as number[]
                x = +x
                y = +y
                const xyStr: string = `${x},${y}`
                if (previous5Step.length === 5) {
                    previous5Step = []
                    exchangeMatrixGrid()
                    break
                } else if (!previous5Step.includes(xyStr)) {
                    previous5Step.push(xyStr)
                    exchangeMatrixGrid()
                    break
                }

                // eslint-disable-next-line
                function exchangeMatrixGrid(): void {
                    ;[matrixSource[y][x], matrixSource[movePointY][movePointX]] = [
                        matrixSource[movePointY][movePointX],
                        matrixSource[y][x],
                    ]
                    movePointX = x
                    movePointY = y
                }
            }
        }
        if (verifCorrect(matrixSource)) {
            matrixSource = upsetMatrixSource(matrixSource)
        }
        return matrixSource

        function getMayMobileIndexs(): string[] {
            const mayMobileindexs: string[] = []
            if (movePointX - 1 !== -1) {
                mayMobileindexs.push(`${movePointX - 1},${movePointY}`)
            }
            if (movePointX + 1 <= matrixW - 1) {
                mayMobileindexs.push(`${movePointX + 1},${movePointY}`)
            }
            if (movePointY - 1 !== -1) {
                mayMobileindexs.push(`${movePointX},${movePointY - 1}`)
            }
            if (movePointY + 1 <= matrixH - 1) {
                mayMobileindexs.push(`${movePointX},${movePointY + 1}`)
            }
            return mayMobileindexs
        }
    }

    function getMovePointIndex(): { x: number; y: number } {
        let x, y
        matrix.source.some((e, yIndex) => {
            return e.some((ee, xIndex) => {
                if (ee.isMovePoint) {
                    x = xIndex
                    y = yIndex
                    return true
                }
                return false
            })
        })
        return {x, y}
    }
}

export default PushSquare
